/* If you need to add @import statements, do so up here */

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


.container-x-padding {
  @apply px-6 sm:px-8 lg:px-10;
}
.container-y-margin {
  @apply my-8 sm:my-12 lg:my-16;
}


.caption {
  @apply mt-2 text-base text-gray-700;
}

.text-link {
  @apply text-blue-700 hover:underline hover:text-blue-800 decoration-1 underline-offset-2 decoration-blue-500 hover:decoration-blue-800;
  @apply group-hover:underline group-hover:text-blue-800 group-hover:decoration-blue-800;
}

.prose {
  @apply prose-a:font-normal prose-a:text-blue-700 prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-blue-500;
  @apply hover:prose-a:text-blue-800 hover:prose-a:decoration-blue-800;
  @apply prose-headings:mb-4;
  @apply prose-h2:text-4xl;
  @apply md:prose-h2:text-5xl;
  @apply prose-h3:text-4xl;
  @apply md:prose-h3:text-3xl;
}
